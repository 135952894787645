<template>
  <div class="chart" id="successRate">
    <div class="chart-wrapper __small">
      <pie-chart v-if="datacollection" :options="options" :chart-data="datacollection"></pie-chart>
    </div>
  </div>
</template>

<script>
import pieChart from '../pie-chart.js'
import { header } from '@/config/index'
export default {
  name: 'successRate',
  data () {
    return {
      datacollection: null,
      height: 300,
      options: {
        title: {
          display: true,
          text: 'Testes executados'
        },
        responsive: true,
        legend: {
          display: true
        },
        tooltips: {
          backgroundColor: '#FFF',
          titleFontSize: 16,
          titleFontColor: '#0066ff',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          displayColors: false
        }
      }
    }
  },
  beforeMount () {
    this.getReports()
  },
  methods: {
    getReports () {
      this.$http.get(this.$api({
        target: 'report/today/success-rate'
      }), {
        headers: header()
      }).then(response => {
        // let data = []
        // let hours = []
        this.datacollection = {
          label: 'Testes executados hoje',
          labels: ['Sucessos', 'Falhas'],
          datasets: [
            {
              label: 'Testes excutados',
              fill: false,
              data: response.data,
              backgroundColor: ['#00DB56', '#FF0053']
            }
          ]
        }
      })
    },
    myStyles () {
      return {
        position: 'relative'
      }
    }
  },
  components: {
    pieChart
  }
}
</script>
