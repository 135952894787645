<template>
  <section class="widget" id="reportsInterval">
    <header class="grid">
      <h3>Relatórios no intervalo definido</h3>
      <b-datepicker
        range
        v-model="interval"
        :min-date="minDate"
        :max-date="maxDate">
      </b-datepicker>
      <b-button class="__primary" @click="intervalActive = true" size="is-large" icon-left="calendar"></b-button>
    </header>
    <div class="content">
      <disk-size-interval :data="diskSizeData"></disk-size-interval>
      <clients-interval :data="clientsData"></clients-interval>
      <!-- <linear&#45;chart&#45;interval :clients="clientsData" :diskSizes="diskSizeData"></linear&#45;chart&#45;interval> -->
    </div>
  </section>
</template>

<script>
import diskSizeInterval from '@/views/dashboard/d-reports/d-reports-interval/disk-size-interval'
import clientsInterval from '@/views/dashboard/d-reports/d-reports-interval/clients-interval'
/**
* @displayName Reports by interval
*/
export default {
  name: 'reportsInterval',
  data () {
    return {
      intervalActive: false,
      interval: null,
      minDate: null,
      maxDate: null,
      diskSizeData: null,
      clientsData: null
    }
  },
  watch: {
    /**
     * Get the disk size on the interval
     *
     * @param {Array} newInterval -
     * @public
     */
    interval (newInterval) {
      let start = new Date(newInterval[0])
      let end = new Date(newInterval[1])
      this.getDiskSize(start.toISOString(), end.toISOString())
      this.getClients(start.toISOString(), end.toISOString())
    }
  },
  beforeMount () {
    this.getRecordsInvervals()
  },
  methods: {
    /**
     * Get the valid intervals for selecting
     *
     * @public
     */
    getRecordsInvervals () {
      this.$http.get(this.$api({
        target: 'client-records-interval'
      })).then(response => {
        this.minDate = new Date(response.data.start)
        this.maxDate = new Date(response.data.end)
      })
    },
    /**
     * Get the disk size on the interval
     *
     * @param {Date} start - Description
     * @param {Date} end - Description
     * @public
     */
    getDiskSize (start, end) {
      this.$http.get(this.$api({
        target: `report/${start}/${end}/disk-size`
      })).then(response => {
        this.diskSizeData = response.data
      })
    },
    /**
     * Description
     *
     * @param {Date} start - Description
     * @param {Date} end - Description
     * @public
     */
    getClients (start, end) {
      this.$http.get(this.$api({
        target: `report/${start}/${end}/clients`
      })).then(response => {
        console.log(response.data)
        this.clientsData = response.data
      })
    }
  },
  components: {
    diskSizeInterval,
    clientsInterval
  }
}
</script>
