<template>
  <section class="widget" id="reportsToday">
    <header class="grid">
      <h3>Relatórios Diários</h3>
    </header>
    <div class="content">
      <written-by-hour></written-by-hour>
      <success-rate></success-rate>
    </div>
  </section>
</template>

<script>
import writtenByHour from './d-reports-today/written-by-hour'
import successRate from './d-reports-today/success-rate'
export default {
  name: 'reportsToday',
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  components: {
    writtenByHour,
    successRate
  }

}
</script>
