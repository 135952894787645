<template>
  <main class="page" id="reports">
    <b-tabs>
      <b-tab-item label="Hoje" icon="hourglass">
        <d-reports-today></d-reports-today>
      </b-tab-item>
      <b-tab-item label="Relatórios" icon="calendar">
        <d-reports-interval></d-reports-interval>
      </b-tab-item>
      <b-tab-item label="Acessos" pack="fas" icon="clipboard-list">
        <d-reports-api></d-reports-api>
      </b-tab-item>
      <b-tab-item label="Downloads" pack="fas" icon="cloud-download-alt">
        <d-reports-download></d-reports-download>
      </b-tab-item>
      <b-tab-item label="Tamanho dos backups" pack="fas" icon="hdd">
        <d-reports-filesize></d-reports-filesize>
      </b-tab-item>
    </b-tabs>
  </main>
</template>

<script>
// import chart from './d-reports/chart.vue'
import dReportsToday from './d-reports/d-reports-today'
import dReportsInterval from './d-reports/d-reports-interval'
import dReportsApi from './d-reports/d-reports-api'
import dReportsDownload from './d-reports/d-reports-download'
import dReportsFilesize from './d-reports/d-reports-filesize.vue'
export default {
  name: 'reports',
  components: {
    dReportsToday,
    dReportsInterval,
    dReportsApi,
    dReportsDownload,
    dReportsFilesize
  }
}
</script>
