<template>
  <div class="chart">
    <div class="chart-wrapper">
      <line-chart v-if="datacollection" :options="options" :chart-data="datacollection"></line-chart>
    </div>
  </div>
</template>

<script>
import lineChart from '../line-chart.js'
import moment from 'moment'
import { header } from '@/config/index'
export default {
  name: 'writtenByHour',
  data () {
    return {
      datacollection: null,
      height: 300,
      options: {
        responsive: true,
        title: {
          display: true,
          text: 'Número de backups escritos por hora'
        },
        legend: {
          display: true
        }
        // tooltips: {
        //   callbacks: {
        //     title: function (tooltipItem, data) {
        //       return `Processados em: ${data['labels'][tooltipItem[0]['index']]}:00`
        //     }
        //   },
        //   backgroundColor: '#FFF',
        //   titleFontSize: 16,
        //   titleFontColor: '#0066ff',
        //   bodyFontColor: '#000',
        //   bodyFontSize: 14,
        //   displayColors: false
        // },
        // scales: {
        //   x: {
        //     type: 'time',
        //     display: true,
        //     scaleLabel: {
        //       display: true,
        //       labelString: 'Date'
        //     },
        //     ticks: {
        //       autoSkip: false,
        //       major: {
        //         fontStyle: 'bold',
        //         fontColor: '#189AD0'
        //       }
        //     }
        //   },
        //   y: {
        //     display: true,
        //     scaleLabel: {
        //       display: true,
        //       labelString: 'value'
        //     }
        //   }
        // }
      },
      seed: Date.now()
    }
  },
  beforeMount () {
    this.getReports()
  },
  methods: {
    increase () {
      this.height += 10
    },
    fillData () {
      this.datacollection = {
      }
    },
    getReports () {
      this.$http.get(this.$api({
        target: 'report/today/written-by-hour-by-server'
      }), {
        headers: header()
      }).then(response => {
        let colors = ['#4dc9f6', '#f67019', '#f53794', '#537bc4', '#acc236', '#166a8f', '#00a950', '#58595b', '#8549ba']
        // let data = []
        let datacollection = {
          label: 'Backups escritos por hora',
          labels: [],
          datasets: []
        }
        let servers = Object.keys(response.data)
        servers.forEach(function (server, index) {
          let hours = Object.keys(response.data[server])
          let dataset = {
            label: server,
            backgroundColor: colors[index],
            borderColor: colors[index],
            fill: false,
            data: []
          }
          hours.forEach(function (hour) {
            if (!datacollection.labels.includes(hour)) {
              datacollection.labels.push(hour)
            }
            dataset.data.push(response.data[server][hour].length)
          })
          datacollection.datasets.push(dataset)
        })
        this.datacollection = datacollection
        // response.data.forEach(server => {
        //   console.log(server)
        //   // hours.push(hour[0].written_at.split(' ')[1].split(':')[0] + ':00')
        //   // let d = {
        //   //   y: hour.length
        //   // }
        //   // data.push(d)
        // })
      })
    },
    newDateString (days) {
      return moment().add(days, 'd').format()
    },
    myStyles () {
      return {
        position: 'relative'
      }
    },
    rand (min, max) {
      let seed = this.seed
      min = min === undefined ? 0 : min
      max = max === undefined ? 1 : max
      this.seed = (seed * 9301 + 49297) % 233280
      return min + (this.seed / 233280) * (max - min)
    },
    randomScalingFactor () {
      return Math.round(this.rand(-100, 100))
    },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  },
  components: {
    lineChart
  }
}
</script>
