<template>
  <section class="widget" id="reportsFileSize">
    <header class="grid">
      <h3>Relatório de tamanho dos backups</h3>
    </header>
    <div class="content">
      <b-table
        :data="clientsData"
        :debounce-search="1000">
        <template slot-scope="props">
          <b-table-column field="slug" label="Cliente" searchable="true">
              {{ props.row.slug }}
          </b-table-column>
          <b-table-column field="total" label="Total em disco">
              {{ parseFilesize(props.row.total) }}
          </b-table-column>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import filesize from 'filesize.js'

/**
* @displayName Downloads por intervalo
*/
export default {
  name: 'reportsFileSize',
  data () {
    return {
      clientsData: []
    }
  },
  beforeMount () {
    this.getClients()
  },
  methods: {
    /**
     * Obtém a lista de clientes.
     *
     * @public
     */
    getClients () {
      this.$http.get(this.$api({
        target: 'clients/bytotal'
      })).then(response => {
        this.clientsData = response.data
      })
    },
    /**
     * Parse the file size to human readable
     *
     * @param {Number} size
     * @returns {String}
     * @public
     */
    parseFilesize (size) {
      return filesize(size)
    }
  }
}
</script>
