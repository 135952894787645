<template>
  <section class="widget" id="reportsAPI">
    <header class="grid">
      <h3>Relatório de acessos à API</h3>
      <b-datepicker
        range
        v-model="interval"
        :min-date="minDate"
        :max-date="maxDate">
      </b-datepicker>
      <b-button class="__primary" @click="intervalActive = true" size="is-large" icon-left="calendar"></b-button>
    </header>
    <div class="content">
      <b-table
        :data="requestsData"
        :columns="requestsColumns"
        :debounce-search="1000"></b-table>
    </div>
  </section>
</template>

<script>
/**
* @displayName Requisições por intervalo
*/
export default {
  name: 'reportsAPI',
  data () {
    return {
      requestsData: [],
      requestsColumns: [
        {
          field: 'created_at',
          label: 'Data'
        },
        {
          field: 'user.email',
          label: 'Usuário',
          searchable: true
        },
        {
          field: 'ip',
          label: 'IP'
        },
        {
          field: 'url',
          label: 'URL'
        }
      ],
      intervalActive: false,
      interval: null,
      minDate: null,
      maxDate: null
    }
  },
  watch: {
    /**
     * Obtém a lista de requisições no intervalo.
     *
     * @param {Array} newInterval -
     * @public
     */
    interval (newInterval) {
      let start = new Date(newInterval[0])
      let end = new Date(newInterval[1])
      this.getRequests(start.toISOString(), end.toISOString())
    }
  },
  beforeMount () {
    this.getRequestsInvervals()
  },
  methods: {
    /**
     * Obtém o intervalo do relatório de requisições à API.
     *
     * @public
     */
    getRequestsInvervals () {
      this.$http.get(this.$api({
        target: 'audit-requests-interval'
      })).then(response => {
        this.minDate = new Date(response.data.start)
        this.maxDate = new Date(response.data.end)
      })
    },
    /**
     * Obtém a lista de requisições no período especificado.
     *
     * @param {Date} start - Data de início
     * @param {Date} end - Data de fim
     * @public
     */
    getRequests (start, end) {
      this.$http.get(this.$api({
        target: `report/${start}/${end}/requests`
      })).then(response => {
        this.requestsData = response.data
      })
    }
  }
}
</script>
