<template>
  <div class="chart">
    <div class="chart-wrapper">
      <line-chart v-if="chartData" :options="options" :chart-data="chartData"></line-chart>
    </div>
  </div>
</template>

<script>
import lineChart from '../line-chart.js'
import moment from 'moment'
export default {
  name: 'writtenByHour',
  props: {
    /**
     * Description
     */
    data: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      datacollection: null,
      height: 300,
      options: {
        responsive: true,
        title: {
          display: true,
          text: 'Clientes ao longo do tempo'
        },
        legend: {
          display: true
        },
        tooltips: {
        },
        scales: {
          yAxes: [{
            ticks: {
              stepSize: 1
            }
          }]
        }
      },
      seed: Date.now()
    }
  },
  beforeMount () {
  },
  computed: {
    chartData () {
      if (this.data) {
        let chartData = {
          label: 'Clients',
          labels: [],
          datasets: [{
            label: 'Clients',
            backgroundColor: '#f67019',
            borderColor: '#f67019',
            fill: false,
            data: []
          }]
        }
        this.data.forEach(d => {
          chartData.labels.push(moment(d.created_at).format('DD/MM/YYYY'))
          chartData.datasets[0].data.push(d.value)
        })
        return chartData
      } else {
        return this.data
      }
    }
  },
  methods: {
    increase () {
      this.height += 10
    },
    fillData () {
      this.datacollection = {
      }
    },
    myStyles () {
      return {
        position: 'relative'
      }
    }
  },
  components: {
    lineChart
  }
}
</script>
