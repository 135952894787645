<template>
  <div class="chart">
    <div class="chart-wrapper">
      <line-chart v-if="chartData" :options="options" :chart-data="chartData"></line-chart>
    </div>
  </div>
</template>

<script>
import lineChart from '../line-chart.js'
import moment from 'moment'
import filesize from 'filesize.js'
export default {
  name: 'writtenByHour',
  props: {
    /**
     * Description
     */
    data: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      datacollection: null,
      height: 300,
      options: {
        responsive: true,
        title: {
          display: true,
          text: 'Tamanho do disk ao longo do tempo'
        },
        legend: {
          display: true
        },
        tooltips: {
          callbacks: {
            title: (tooltipItem, data) => filesize(tooltipItem[0].yLabel * 1024)
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, values) {
                return filesize(value * 1024)
              }
            }
          }]
        }
      },
      seed: Date.now()
    }
  },
  beforeMount () {
  },
  computed: {
    chartData () {
      if (this.data) {
        let colors = ['#4dc9f6']
        // let data = []
        let chartData = {
          label: 'Disk size',
          labels: [],
          datasets: [{
            label: 'Disk size',
            backgroundColor: colors[0],
            borderColor: colors[0],
            fill: false,
            data: []
          }]
        }
        this.data.forEach(d => {
          chartData.labels.push(moment(d.created_at).format('DD/MM/YYYY'))
          chartData.datasets[0].data.push(d.storage_size)
          // console.log(filesize(d.storage_size * 1024))
        })
        // let servers = Object.keys(response.data)
        // servers.forEach(function (server, index) {
        //   let hours = Object.keys(response.data[server])
        //   let dataset = {
        //     label: server,
        //     backgroundColor: colors[index],
        //     borderColor: colors[index],
        //     fill: false,
        //     data: []
        //   }
        //   hours.forEach(function (hour) {
        //     if (!datacollection.labels.includes(hour)) {
        //       datacollection.labels.push(hour)
        //     }
        //     dataset.data.push(response.data[server][hour].length)
        //   })
        //   datacollection.datasets.push(dataset)
        // })
        // this.datacollection = datacollection
        return chartData
      } else {
        return this.data
      }
    }
  },
  methods: {
    increase () {
      this.height += 10
    },
    fillData () {
      this.datacollection = {
      }
    },
    myStyles () {
      return {
        position: 'relative'
      }
    }
  },
  components: {
    lineChart
  }
}
</script>
